<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-hand-receiving text-primary"></i>{{ $t('membership.1') }}</div>
		</div>
		<div class="section_right_inner">
			<div class="s_left">
				<!--s_left-->
				<div class="withdrawal_section01">
					<!--withdrawal_section01-->
					<p class="withdrawal_section01_t"><span>{{ $t('membership.2') }}</span></p>
					<div class="total_box">
						<div class="total_box_inner">
							<div class="total_box_title">{{ $t('membership.3') }} NINIPAY</div>
							<div class="total_box_balance">
								<p class="allowed">{{numberWithCommas(balance)}}<span></span></p>
							</div>
						</div>
					</div>
					
					<div name="packagefrm">
						<div class="withdrawal_input_box">
							<table style="width:100%;">
								<tbody>
									<tr>
										<td>{{ $t('membership.4') }}</td>
										<td class="d-flex align-items-center" style="width:100%; padding:10px">
											<select class="withdrawal_input01" v-model='membership'>
												<option value='' style='display: none'>{{ $t('membership.5') }}</option>
												<option :value='item.code' style='' v-for="(item,index) in membership_list" :key="index">{{item.name}}</option>
											</select>
											<i class="fas fa-chevron-down"></i>
										</td>
										
									</tr>
									<!-- <tr>
										<td>{{$t("transfer.7")}}</td>
										<td class="d-flex align-items-center" style="width:100%; padding:10px">
											<input type="password" name="addres" class="withdrawal_input01" v-model="otp_code"  autocomplete='off'>
										</td>
									</tr> -->
								</tbody>
							</table>
						</div>
						<div style="text-align: center;" class="mt-3">
							<input type="button" class="withdrawal_botton" :value="`${$t('membership.6')}`" @click="AddMemberShip()">
						</div>
					</div>
				</div>
			</div>
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

	export default {
		components: {
		},
		data(){
			return{
				balance: 0,
				rate: 0,
				price : '',
				symbol : 'ETH',
				eth_amount:'',
				address: '',
				tera:'',
				bnb_balance :'',
				usd_balance :'',
				membership :'',
				membership_list : [],
				coin_list : [],
				coin : '',
				balance : '',
				otp_code : '',
			}
		},
	    mounted(){
			// this.CheckOTP();
			this.GetCoinInfo();
			this.GetMemberShipList();
			// this.GetInfo();
			// this.GetPrice();
		},
		methods: {
			CheckOTP(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/withdraw/CheckOTP`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.otp_able = body.otp_able;
								
								if(body.otp_able =='N'){
									this.$router.push({path:`/${this.$i18n.locale}/otp`})
								}

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			GetCoinInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/membership/GetCoinInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.balance = body.balance;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			
			GetMemberShipList(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/membership/GetMemberShipList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.membership_list = body.list;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			AddMemberShip : function(){

				const membership = this.membership;
				// const otp_code = this.otp_code;
				if(membership ==''){
					this.$alert(this.$t("membership.5"))
					return false;
				}

				// if(otp_code ==''){
				// 	this.$alert(this.$t("coin.20"))
				// 	return false;
				// }
				

				let loader = this.$loading.show({loader: 'dots',color:'#ff0073'});
				
				// const body = {membership,otp_code};
				const body = {membership};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/membership/AddMemberShip`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								loader.hide();	
								this.$alert(this.$t('membership.12')).then(
									()=>{
										this.$router.push({path:`/${this.$i18n.locale}/SalesList`})
									}
								);
							}else if(res.data.code=='100'){
								loader.hide();									
								this.$alert(this.$t('membership.11'));
							}else if(res.data.code=='500'){
								loader.hide();									
								this.$alert(this.$t("coin.22"));
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}
</script>
<style>
</style>